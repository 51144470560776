<template>
  <div class="flex justify-center pt-2  px-4 pb-10 mb-20 overflow-y-auto overscroll-x-none  bg-gray-100">
    <div class="flex flex-col w-full bg-gray-100 space-y-6">
      <!-- ข้อมูล Optional -->     
      <event-optional
        class="my-5"
        :content="content"
        :saveChangeOptional="saveChangeOptional"
        :eventHashtag="eventHashtag"
        :timerSettimg="timerSettimg"
        @input-eventHashtag="(hashtag)=>{eventHashtag = hashtag}"
      />
    </div>
  </div>
</template>

<script setup>
import { provinceList } from "../public/data/province";
import { useEventStore } from "@/stores/event";
import ShippingInfo from "@/components/event/ShippingInfo"

import dayjs from "dayjs";

import { useContentRealtimeSlide } from '~/composables/content/v2/useContentRealtimeSlide'
const { content } = useContentRealtimeSlide('slide-info')

const useEvent = useEventStore();

//Event Init
const eventData = ref();

//Optional
const eventHashtag = ref("");
const timerSettimg = ref({});



const init = async () => {
  const event = useEvent.event ;
  if(!event) return 
  eventData.value = event;
  eventHashtag.value = event.information.hashtag;
  timerSettimg.value = event.settings.timer;  
};

onBeforeMount(async()=>await init())


const saveChangeOptional = async () => {
  const initSettingInfo = eventData.value.settings;
  const settings = { ...initSettingInfo, timer: timerSettimg.value };
  const information = {
    ...eventData.value.information,
    hashtag: eventHashtag.value || "",
  };

  console.log("settings", settings)
  console.log("information", information)

  await useEvent.updateEvent({ settings , information });
};


</script>

<style scoped>

</style>
