<template>
  <div class="p-3">
    <div class="bg-white rounded-xl shadow">
      <div class=" mx-auto max-w-7xl p-6">
        <div class="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2
            class="text-2xl font-bold leading-10 tracking-tight text-gray-900"
          >
            {{content?.title}}
          </h2>
          <div class="mt-5 space-y-6 divide-y divide-gray-900/10">
            <div class="pt-6" v-for="(step, index) in content?.list" :key="index">
              <p class="text-lg font-bold space-x-2">
                <span>{{index + 1}}.</span>
                <span>{{step.title}}</span>
              </p>
              <p>{{step.description}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useContentRealtimeSlide } from '~/composables/content/v2/useContentRealtimeSlide'
const { content } = useContentRealtimeSlide('slide-howToUse')

</script>

<style scoped>

</style>