<template>
  <div class="pt-8 pb-8">
    <PreweddingPreviewModal
      :imgUrl="previewSelected.imgUrl"
      :isPreview="previewSelected.isPreview"
      :index="previewSelected.index"
      @closed="previewSelected.isPreview = false"
      @remove="onRemoveImage(previewSelected.index)"
    />

    <PreweddingUploadModal     
      :content="content"
      :buttons="content?.uploadButton"
      :isOpenUploadModal="isOpenUploadModal"     
      :onUploadMultiImage="onUploadMultiImage" 
      @closed="isOpenUploadModal = false"     
    />

    <!-- SHOW TOOLBAR FOR EVENT OWNER -->
    <div v-if="eventStore.isOwner" class="mb-5 flex justify-between items-center">

      <p v-if="isShowMultipleSelect > 0" class="ml-5 text-gray-700 text-xl">
        <span>{{content?.selected}}</span> 
        <span>{{ countImageSelected }} {{content?.unit}}</span>
      </p
      >
      <p v-else class="ml-3 text-gray-700 text-lg space-x-2">
        <span>{{content?.quantity}}</span>
        <span>{{ preweddingList.length }}/{{ preweddingUploadLimit }}</span>
      </p>

      <div class="flex flex-row">
        <button
          v-if="!isShowMultipleSelect && preweddingList.length > 0"
          @click="isShowMultipleSelect = !isShowMultipleSelect"
          class="bg-transparent hover:bg-red-500 text-gray-700 font-semibold hover:text-white py-2 px-4 border border-gray-500 hover:border-transparent rounded"
        >
          <PencilSquareIcon class="w-5 h-5" />
        </button>

        <div v-if="!isShowMultipleSelect" class="ml-1">
          <!-- <input
            type="file"
            id="file-prewedding"
            name="file-prewedding"
            accept="image/*"
            multiple
            hidden
            @input="onUploadMultiImage($event)"
          />
          <label
            for="file-prewedding"
            class="h-10 px-3 py-2 flex justify-center items-center border border-gray-700 text-gray-500 rounded cursor-pointer mr-1 shadow-lg"
          >
            <ArrowUpTrayIcon class="w-5 h-5 mr-2" />
            <span>Upload</span>
          </label> -->
          <button    
          @click="handleOpenUploadModal"
          class="h-10 px-3 py-2 flex justify-center items-center border border-gray-700 text-gray-500 rounded cursor-pointer mr-1 shadow-lg"
        >
           <ArrowUpTrayIcon class="w-5 h-5 mr-2" />
            <span>Upload</span>
        </button>

        </div>

        <!-- <button
          v-if="!isShowMultipleSelect"
          @click="$router.push('/wish/desktop/info')"
          class="mr-2 bg-transparent text-gray-700 font-semibold hover:text-white py-2 px-4 border border-gray-500 hover:border-transparent rounded"
        >
          <ComputerDesktopIcon class="w-5 h-5" />
        </button> -->
      </div>
    </div>

    <!-- SHOW PREWEDDING NUMBER IF NOT THE OWNER OF THE EVENT-->
    <div v-if="!eventStore.isOwner" class="mb-5 flex justify-between items-center">
     <span  class="ml-5 text-gray-700  text-lg">
       จำนวน {{ preweddingList.length }}/{{ preweddingUploadLimit }}
      </span>
    </div>

    

    <!-- DISPLAY IMAGES -->
    <div class="mb-20 grid grid-cols-2 gap-4 px-3">
      <div
        class="rounded overflow-hidden shadow-lg bg-gray-900 relative"
        v-for="(prewedding, index) in preweddingList"
        :key="prewedding.key"
      >
        <div v-if="isShowMultipleSelect" class="absolute top-1 left-1 z-10">
          <input
            :id="index"
            type="checkbox"
            v-model="imageSelected[prewedding.key]"
            @change="onChangeSelectedImage(prewedding.key)"
            class="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
          />
        </div>

        <UtilsLoadingSkeletionImage
          @click="onPreviewPrewedding(prewedding, index)"
          :imageUrl="prewedding.thumbnail"
        />
      </div>

      <!-- Skeleton -->
      <div v-for="t in tempUploadArray" :key="t">
        <div
          v-if="
            countTempUpload !== preweddingList.length && countTempUpload > 0
          "
          class="rounded shadow-lg relative"
        >
          <PreweddingSkeletonImage />
        </div>
      </div>
    </div>

    <div
      v-if="preweddingList.length == 0"
      class="flex justify-center items-center mt-20"
    >
      <div class="text-sm text-gray-500">{{content?.empty}}</div>
    </div>
  </div>

  <!-- WHEN CLICK EDIT  SHOW BOTTOM BAR FOR REMOVE       -->
  <div
    v-if="isShowMultipleSelect"
    class="fixed bottom-0 left-0 right-0 z-50 p-4 bg-white shadow-lg flex flex-row justify-between items-center"
  >
    <p class="ml-2 text-gray-700 text-lg space-x-3">
      <span>{{content?.selected}}</span> 
      <span>{{ countImageSelected }} {{content?.unit}}</span>
    </p>

    <div>
      <button
        @click="onRemoveMultiImage"
        :disabled="!hasSelectImage"
        :class="{
          'bg-gray-200 text-gray-300': !hasSelectImage,
          'bg-red-500 text-white': hasSelectImage,
        }"
        class="mr-2 font-semibold py-2 px-4 rounded"
      >
        Remove
      </button>
      <button
        @click="cancelSelectImage"
        class="mr-2 bg-gray-100 text-gray-500 font-semibold py-2 px-4 rounded"
      >
        Cancel
      </button>
    </div>
  </div>

  <UtilsLoadingUpload
    v-if="uploadLoading.length > 0"
    v-model="uploadLoading.isOpen"
    :indexAt="uploadLoading.indexAt"
    :length="uploadLoading.length"
    :text="uploadLoading.text"
  />
</template>
<script setup>
import {
  ArrowUpTrayIcon,
  PencilSquareIcon,
  ComputerDesktopIcon,
} from "@heroicons/vue/24/solid";
import Swal from "sweetalert2";
import { usePreweddingStore } from "@/stores/prewedding";
import { useAppStateStore } from "@/stores/appState";
import { useEventStore } from "@/stores/event";
import usePrewedding from "@/composables/usePrewedding";

import { useContentRealtimeSlide } from '~/composables/content/v2/useContentRealtimeSlide'
const { content } = useContentRealtimeSlide('slide-photos')

const preweddingStore = usePreweddingStore();
const appStateStore = useAppStateStore();
const eventStore = useEventStore();
const preweddingList = computed(() => {
  return preweddingStore.imageList.sort((a, b) => {
    const uploadedA = a && a.result && a.result.uploaded
    const uploadedB = b && b.result && b.result.uploaded
    return new Date(uploadedA) - new Date(uploadedB);
  });
});

const limitUpload = computed(() => preweddingStore.limitUpload);
const preweddingUploadLimit = computed(() =>
  eventStore.event && eventStore.event.package == "free" ? 30 : 200
);

const previewSelected = ref({ imgUrl: "", isPreview: false });
const isOpenUploadModal = ref(false)
const isShowUpload = ref(false);
const uploadLoading = ref({
  isOpen: false,
  length: 0,
  indexAt: 0,
  text: "Upload",
});
const imageSelected = ref({});
const isShowMultipleSelect = ref(false);
const isRemoveMode = ref(false);
const tempUploadArray = ref([]);
const countTempUpload = ref(0);

const countImageSelected = computed(() =>
  usePrewedding().countTrueValues(imageSelected.value)
);

watch(preweddingList, () => {
  let count = countTempUpload.value - preweddingList.value.length;

  //IF REMOVE
  if (isRemoveMode.value) {
    count = preweddingList.value.length - countTempUpload.value;
  }

  tempUploadArray.value = Array.from({ length: count }, (_, i) => i);
});

const onChangeSelectedImage = (imageKey) => {
  const onlySelected = Object?.fromEntries(
    Object.entries(imageSelected.value)?.filter(([key, value]) => value === true)
  )
  
  imageSelected.value  = onlySelected
}

const hasSelectImage = computed(() => {
  const newArr = Object.values(imageSelected.value);
  return newArr.some((s) => s);
});

const handleOpenUploadModal = ()=>{
  isOpenUploadModal.value = false
  setTimeout(()=>{
    isOpenUploadModal.value = true
  },200)
}
const onPreviewPrewedding = (prewedding, index) => {
  previewSelected.value = usePrewedding().onPreviewPrewedding(
    prewedding,
    index
  );
};

const onUploadMultiImage = async (event) => {
  const isExceedSize = checkFileSize(event.target.files)

  if(event.target.files?.length > 10) {
    Swal.fire({
      title: "แจ้งเตือน",
      html: `
        <p>สามารถอัพโหลดรูปได้สูลสุด </p>
        <p>ไม่เกิน 10 รูปต่อครั้ง</p>
      `,
      icon: "error",
      showCancelButton: false,
    })
    isOpenUploadModal.value = false
    return 
  }

  if(isExceedSize) {
    Swal.fire({
      title: "แจ้งเตือน",
      html: `
        <p>ไม่สามารถอัพโหลดรูปได้ </p>
        <p>เนื่องจากมีไฟล์รูปเกินขนาดที่กำหนด</p>
        <p class="text-sm mt-5"> กรุณาอัพโหลดรูปที่มีขนาดต่ำกว่า 20 MB / รูป </p>
      `,
      icon: "error",
      showCancelButton: false,
    })
    isOpenUploadModal.value = false
    return 
  }

  isOpenUploadModal.value = false
  await usePrewedding().onUploadMultiImage(
    event,
    countTempUpload,
    tempUploadArray,
    uploadLoading
  );
};

const checkFileSize = (files) => {

  if(files.length === 0) {
    return
  }

  const maxSizeInMB = 20;
  const maxSizeInBytes = 20000000;

  let count = 0

  for (let index = 0; index < files.length; index++) {
    const file = files[index];
    console.log(file.size)

    if (file.size > maxSizeInBytes) {
      count++
    }
  }

  return count > 0 ? true : false
}

const onRemoveMultiImage = async () => {
  isRemoveMode.value = true;
  await usePrewedding().onRemoveMultiImage(
    imageSelected,
    uploadLoading,
    isShowMultipleSelect
  );
  isRemoveMode.value = false;
};

const onRemoveImage = async (index) => {
  isRemoveMode.value = true;
  await usePrewedding().onRemoveImage(index);
  isRemoveMode.value = false;
};

const cancelSelectImage = () => {
  usePrewedding().cancelSelectImage(isShowMultipleSelect, imageSelected);
};
</script>
